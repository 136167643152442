import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ObservationLegal from '@/Models/ObservationLegal'
import Crm from '@/Models/Crm'
import http from '@/services/http'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormObservationLegal extends Vue {
  @Prop({ required: false, default: null })
  objMember!: Crm
  @Prop({ required: false, default: null })
  objRow!: ObservationLegal
  @Prop({ required: false, default: null })
  isAction!: string

  objObservationLegal: ObservationLegal = new ObservationLegal()
  loading = false

  get is_login(): boolean {
    return authModule.isAuthenticated
  }
  mounted() {
    this.objObservationLegal.user = this.objMember.member_number
    if (this.objRow && this.isAction == 'edit') {
      console.log(this.objRow)
      this.$set(this, 'objObservationLegal', this.objRow)
    }
  }
  async confirmation() {
    const observation = this.observation(this.objObservationLegal)
    switch (this.isAction) {
      case 'add':
        await this.createObservationLegal(observation)
        break
      case 'edit':
        await this.updateObservationLegal(observation)
        break
      default:
        console.log('ups')
        break
    }
  }
  async createObservationLegal(objObservationLegal: any) {
    try {
      await http.post(`api/legal/arcr_legal_observations/`, objObservationLegal)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error) {
      console.error(error)
      this.loading = false
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async updateObservationLegal(objObservationLegal: any) {
    try {
      await http.put(
        `api/legal/arcr_legal_observations/${this.objRow.id}/`,
        objObservationLegal,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      this.loading = false
      console.log(error)
      switch (error.response.data.member_number[0]) {
        case 'Ya existe Crm Cliente Archivo con este member number.':
          this.$buefy.toast.open({
            message: `Ya exite un cliente con el mismo numero de miembro`,
            type: 'is-danger',
          })
          break
        default:
          this.$buefy.toast.open({
            message: `Ups!, algo salio mal`,
            type: 'is-danger',
          })
          break
      }
      /* this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: "is-danger",
      }) */
    }
  }
  observation(obj: ObservationLegal) {
    const observation = {
      id: obj.id,
      date_registered: Helpers.dateFormat_db(obj.date_registered),
      observation: obj.observation,
      user_id: obj.user,
      legal_id: menuModule.id_param,
    }
    return observation
  }
  close() {
    this.objObservationLegal = new ObservationLegal()
    this.$emit('cleanForm')
  }
}
